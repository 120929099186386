import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import refs from './refs/reducers'
import inits from './inits/reducers'
import payments from './payments/reducers'
import options from './options/reducers'
import posSettings from './posSettings/reducers'
import finance from './finance/reducers'
import billing from './billing/reducers'
import billingPos from './billingPos/reducers'
import companies from './companies/reducers'
import translation from './translation/reducers'
import broadcast from './broadcast/reducers'
import show_nps from './nps/reducers'
import show_hr_addon from './hris/reducers'
import tutorials from './tutorials/reducers'
import reviews from './reviews/reducers'
import loginForm from './loginForm/reducers'
import customColumn from './customColumn/reducers'
import defaultMessage from './defaultMessage/reducers'
import multiCurrency from './currencies/reducers'
import salutations from './salutations/reducers'
import maintenance from './maintenance/reducers'
import reasons from './reasons/reducers'
import onboardingChecklist from './onboardingChecklist/reducers'
import outlets from './outlets/reducers'
import maxCompanies from './maxCompanies/reducers'
import financeTransTotal from './financeTransTotal/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    refs,
    inits,
    payments,
    options,
    posSettings,
    finance,
    billing,
    billingPos,
    companies,
    translation,
    broadcast,
    show_nps,
    show_hr_addon,
    tutorials,
    reviews,
    loginForm,
    customColumn,
    defaultMessage,
    multiCurrency,
    salutations,
    reasons,
    maintenance,
    max_companies: maxCompanies,
    onboardingChecklist,
    outlets,
    financeTransTotal,
  })
