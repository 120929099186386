import React from 'react'
import { Button, Form, Input } from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import styles from './styles.module.scss'

// website_id ini di allow karena sudah ada
// nama tag yang melanggar dari rules. Sehingga BE dan FE
// membuat rules special untuk user dengan website_id ini
const specialWebsiteId = [
  125376, // AllSet Event Hire
  125564, // PT Eticon Rekayasa Teknik
  130013, // SATRIO LANGIT TRANS
]

class FinanceTagForm extends React.Component {
  state = {
    color: '#E4E9F0',
  }

  nameRef = React.createRef()

  componentDidMount() {
    const { innerRef } = this.props
    if (innerRef.current) {
      innerRef.current.resetFields()
    }
    if (this.nameRef.current) {
      setTimeout(() => this.nameRef.current.focus(), 200)
    }
    if (this.props.show && this.props.tag) {
      this.setState({ color: this.props.tag?.color ?? '#E4E9F0' })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        const form = this.props.innerRef.current
        if (form) {
          form.resetFields()
        }
      }
    }
    if (this.props.show && !_.isEqual(this.props.tag, prevProps.tag)) {
      this.setState({ color: this.props.tag?.color ?? '#E4E9F0' })
    }
  }

  render() {
    const { tag, intl, innerRef, websiteId } = this.props
    const { color, displayColorPicker } = this.state

    const isSpecialWebsiteId = specialWebsiteId.includes(websiteId)
    const maxTagName = isSpecialWebsiteId ? 150 : 50
    const specialRegex = isSpecialWebsiteId ? /^[ A-Za-z0-9_@./#&+-]*$/ : /^[\w\s.]+$/

    return (
      <Form
        id="tagForm"
        ref={innerRef}
        layout="vertical"
        initialValues={{
          name: tag?.name ?? null,
          color: tag?.color ?? color,
        }}
        onFinish={this.props.submitHandler}
      >
        <Form.Item
          label={intl.formatMessage({ id: 'financeTag.name' })}
          name="name"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'financeTag.please_input_the_tag_name' }),
            },
            {
              max: maxTagName,
              message: intl.formatMessage(
                { id: 'financeTag.max_characters' },
                { value: maxTagName },
              ),
            },
            {
              min: 2,
              message: intl.formatMessage({ id: 'financeTag.min_characters' }, { value: 2 }),
            },
            {
              pattern: specialRegex,
              message: intl.formatMessage({
                id: isSpecialWebsiteId
                  ? 'financeTag.alphanumeric_and_this_regex_allowed'
                  : 'financeTag.only_alphanumeric_and_dot_allowed',
              }),
            },
          ]}
        >
          <Input
            ref={this.nameRef}
            placeholder={intl.formatMessage({ id: 'financeTag.name' })}
            autoFocus
          />
        </Form.Item>
        <Form.Item name="color" label={intl.formatMessage({ id: 'tags.choose_a_color' })}>
          <Button
            htmlType="button"
            className={styles.buttonPickerColor}
            onClick={() =>
              this.setState((prevState) => ({
                ...prevState,
                displayColorPicker: !prevState.displayColorPicker,
              }))
            }
          >
            <div style={{ '--tag-color': color }} />
            <p className="mb-0 ml-2 mr-1">{color}</p>
          </Button>
          {displayColorPicker ? (
            <div className={styles.picker}>
              <button
                type="button"
                className={styles.overlay}
                onClick={() => this.setState({ displayColorPicker: false })}
              />
              <ChromePicker
                color={color}
                disableAlpha
                onChange={(newColor) => {
                  innerRef.current.setFieldValue('color', newColor.hex)
                  this.setState({ color: newColor.hex })
                }}
              />
            </div>
          ) : null}
        </Form.Item>
      </Form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    websiteId: state.companies.website_id,
  }
}

const Component = connect(mapStateToProps)(injectIntl(FinanceTagForm))

export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
