import { updateObject } from 'redux/utility'
import * as actionTypes from 'redux/actionTypes'

const initialState = {}

const setFinanceTransTotal = (state, { payload }) => {
  return updateObject(state, {
    ...payload,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_TRANS_TOTAL:
      return setFinanceTransTotal(state, action)
    default:
      return state
  }
}

export default reducer
