import React, { memo, useMemo } from 'react'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { LinkTransType } from 'components/UI'
import { formatDate, formatTime } from 'utils/formatting'
import Changes from 'components/apps/Notification/Timeline/Changes'
import { compareWithJSON } from 'utils/helper'
import style from './style.module.scss'

const TimeLineItem = ({ isPaidFeature, item, onHideParent, propertyTimeZone }) => {
  const { formatMessage } = useIntl()

  const innerMemo = useMemo(() => {
    let desc = ''
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(item.extra)) {
      if (key.toLowerCase() !== 'pdf url') {
        desc += `${key}: ${value} `
      }
    }
    let typeDonut = style.info
    if (item.action_type_cat === 'delete') {
      typeDonut = style.danger
    } else if (item.action_type_cat === 'add') {
      typeDonut = style.success
    }
    return { desc, typeDonut }
  }, [item.action_type_cat, item.extra])
  return (
    <li className={`${style.item} row`} key={item.id}>
      <div className="col-3" style={{ paddingRight: 0 }}>
        <div className={`${style.itemTime}`} style={{ display: isPaidFeature ? 'none' : '' }}>
          {dayjs(item.created_at).tz(propertyTimeZone, true).fromNow()}
        </div>
      </div>
      <div className={`${style.itemSeparator}`}>
        <div className={`${style.donut} ${innerMemo.typeDonut}`} />
      </div>
      <div className={`${style.itemContent} col-9`}>
        <div>
          {item.action_type_id === 3 ? (
            <span>{item.desc.trans_type}</span>
          ) : item.trans_type_id === 1025 ? (
            <span>{formatMessage({ id: 'report.reports' })}</span>
          ) : (
            <LinkTransType
              title={item.desc.trans_type}
              transTypeId={item.trans_type_id}
              data={{
                id: item.tran_id,
                accountId: item.bank_account_id,
                warehouseId: item.warehouse_id,
                userId: item.user_id,
              }}
              onClick={onHideParent}
            />
          )}
          {` ${item.desc.action}`}
          {item.desc.name && formatMessage({ id: 'audit.by' })}
          {item.desc.name}
        </div>
        <small>{innerMemo.desc}</small>
        <a
          href={`https://www.whois.com/whois/${item.remote_addr}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary d-block"
        >
          {item.remote_addr}
        </a>
        <small>
          {formatDate(item.created_at)} {formatTime(item.created_at)}
        </small>
        <Changes desc={item.desc} changes={item.changes} />
      </div>
    </li>
  )
}

export default memo(TimeLineItem, compareWithJSON)
