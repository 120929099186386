import axios from 'axios'
import { cleanBlankValue, getOnce } from 'utils/helper'

export const getFinanceExpenses = (params) => {
  return getOnce('/finance/expenses', { params })
}

export const getRecapFinanceExpenses = (params) => {
  return getOnce('/finance/expenses/recap', { params })
}

export const getFinanceExpensesOverview = (params) => {
  return axios.get('/finance/expenses/overview', { params })
}

export const getFinanceExpensesAccountChart = (params) => {
  return axios.get('/finance/expenses/accountStats', { params })
}

export const getFinanceExpensesContactChart = (params) => {
  return axios.get('/finance/expenses/contactStats', { params })
}

export const addFinanceExpense = (data) => {
  return axios.post('/finance/expenses', data)
}

export const editFinanceExpense = (data) => {
  return axios.put(`/finance/expenses/${data.id}`, data)
}

export const deleteOnAddFinanceExpenseAttachment = (url) => {
  return axios.delete('/finance/expenses/attachments', { data: { url } })
}

export const deleteOnEditFinanceExpenseAttachment = (id, url) => {
  return axios.delete(`/finance/expenses/${id}/attachments`, { data: { url } })
}

export const getFinanceExpense = (id) => {
  return axios.get(`/finance/expenses/${id}`)
}

export const getFinanceExpenseLog = (id, params) => {
  return axios.get(`/finance/expenses/${id}/logs`, { params })
}

export const deleteFinanceExpense = (id) => {
  return axios.delete(`/finance/expenses/${id}`)
}

export const uploadImportFinanceExpense = (data) => {
  return axios.postForm('/finance/expenses/uploadImport', data)
}

export const executeImportFinanceExpense = (data) => {
  return axios.post('/finance/expenses/executeImport', data)
}

export const exportFinanceExpense = (params) => {
  const URL = params.status_id === 'recurring' ? 'expenses/recurring/export' : 'expenses/export'
  if (params.status_id === 'recurring') {
    delete params.status_id
  }
  const query = cleanBlankValue(params)
  return axios.get(`/finance/${URL}`, { responseType: 'arraybuffer', params: query })
}

export const addRecurringExpense = (data) => {
  return axios.post('/finance/expenses/recurring', data)
}

export const editRecurringExpense = (data) => {
  return axios.put(`/finance/expenses/recurring/${data.id}`, data)
}

export const getRecurringExpense = (id) => {
  return axios.get(`/finance/expenses/recurring/${id}`)
}

export const deleteRecurringExpense = (id) => {
  return axios.delete(`/finance/expenses/recurring/${id}`)
}

export const getRecurringExpenses = (params) => {
  return getOnce('/finance/expenses/recurring', { params })
}

export const inputMassDeleteFinanceExpense = (data) => {
  return axios.post('/finance/expenses/inputMassDelete', data)
}

export const executeMassDeleteFinanceExpense = (data) => {
  return axios.delete('/finance/expenses/executeMassDelete', { data })
}

export const approveFinanceExpense = ({ id }) => {
  return axios.post(`/finance/expenses/${id}/approve`)
}

export const rejectFinanceExpense = ({ id, body }) => {
  return axios.post(`/finance/expenses/${id}/reject`, body)
}

export const revertApprovalFinanceExpense = ({ id }) => {
  return axios.post(`/finance/expenses/${id}/revert`)
}

export const getMassDownloadFinanceExpense = (params) => {
  return axios.get('/finance/expenses/massDownload', { params })
}
