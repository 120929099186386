import axios from 'axios'

import { cleanBlankValue, getOnce } from 'utils/helper'

export const getFinanceFixedAssets = (params) => {
  return getOnce('/finance/fixedAssets', { params })
}

export const addFinanceFixedAsset = (data) => {
  return axios.post('/finance/fixedAssets', data)
}

export const editFinanceFixedAsset = (data) => {
  return axios.put(`/finance/fixedAssets/${data.id}`, data)
}

export const getFinanceFixedAsset = (id) => {
  return axios.get(`/finance/fixedAssets/${id}`)
}

export const deleteFinanceFixedAssetPhoto = (url) => {
  return axios.delete('/finance/fixedAssets/photo', { data: { url } })
}

export const deleteFinanceFixedAssetPhotoOnEdit = ({ url, id }) => {
  return axios.delete(`/finance/fixedAssets/${id}/photo`, { data: { url } })
}

export const getFinanceFixedAssetLog = (id, params) => {
  return axios.get(`/finance/fixedAssets/${id}/logs`, { params })
}

export const deleteFinanceFixedAsset = (id) => {
  return axios.delete(`/finance/fixedAssets/${id}`)
}

export const disposeFinanceFixedAsset = ({ id, ...data }) => {
  return axios.post(`/finance/fixedAssets/${id}/dispose`, data)
}

export const registerFinanceFixedAsset = ({ id, ...data }) => {
  return axios.post(`/finance/fixedAssets/${id}/register`, data)
}

export const getFinanceFixedAssetToDepreciates = (params) => {
  return axios.get('/finance/fixedAssets/toDepreciates', { params })
}

export const depreciateFinanceFixedAsset = (data) => {
  return axios.post('/finance/fixedAssets/depreciate', data)
}

export const revertDisposeFinanceFixedAsset = (id) => {
  return axios.post(`/finance/fixedAssets/${id}/revertDispose`)
}

export const rollbackDepreciationFinanceFixedAsset = (id) => {
  return axios.post(`/finance/fixedAssets/${id}/rollbackDepreciation`)
}

export const massDeleteFinanceFixedAsset = (ids) => {
  return axios.delete('/finance/fixedAssets/massDelete', { data: { id: ids } })
}

export const getFinanceFixedAssetTransaction = (tranId) => {
  return axios.get(`/finance/fixedAssets/transactions/${tranId}`)
}

export const uploadImportFinanceFixedAsset = (data) => {
  return axios.postForm('/finance/fixedAssets/uploadImport', data)
}

export const executeImportFinanceFixedAsset = (data) => {
  return axios.post('/finance/fixedAssets/executeImport', data)
}

export const exportFinanceFixedAsset = (params) => {
  params = cleanBlankValue(params)

  return axios.get('/finance/fixedAssets/export', { responseType: 'arraybuffer', params })
}

export const inputMassDeleteFinanceFixedAsset = (data) => {
  return axios.post('/finance/fixedAssets/inputMassDelete', data)
}

export const executeMassDeleteFinanceFixedAsset = (data) => {
  return axios.delete('/finance/fixedAssets/executeMassDelete', { data })
}

export const getFinanceFixedAssetAccumulatedDepreciation = ({ id, ...params }) => {
  return axios.get(`/finance/fixedAssets/${id}/accumulatedDepreciation`, { params })
}

export const getFinanceFixedAssetsTopAcquisitionPriceStats = () => {
  return axios.get('/finance/fixedAssets/topAcquisitionPriceStats')
}

export const getFinanceFixedAssetsTopAssetValueStats = () => {
  return axios.get('/finance/fixedAssets/topAssetValueStats')
}

export const getFinanceFixedAssetValueStats = (params) => {
  return axios.get('/finance/fixedAssets/assetValueStats', { params })
}

export const getFinanceFixedAssetsDepreciationStats = (params) => {
  return axios.get('/finance/fixedAssets/depreciationStats', { params })
}

export const getFinanceFixedAssetsAssetDisposalStats = (params) => {
  return axios.get('/finance/fixedAssets/assetDisposalStats', { params })
}

export const getFinanceFixedAssetsAutomaticDepreciateLogs = (params, justGetOnce = false) => {
  if (justGetOnce) return getOnce('/finance/fixedAssets/automaticDepreciateLogs', { params })
  return axios.get('/finance/fixedAssets/automaticDepreciateLogs', { params })
}
