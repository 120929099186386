import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'
import { optionsSelector } from 'redux/selectors'
import { Empty, Skeleton, Spin } from 'antd/lib'
import TimeLineItem from './TimeLineItem'

const Timeline = ({
  loading,
  isPaidFeature,
  data,
  onLoadMore,
  hasMore,
  loadingMore,
  onHideParent,
  isMain = false,
  getScrollParent,
}) => {
  const options = useSelector(optionsSelector)

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={1}
      loadMore={onLoadMore}
      hasMore={!loadingMore && hasMore}
      useWindow={isMain}
      getScrollParent={getScrollParent}
    >
      {loading ? (
        <>
          <Skeleton paragraph={{ rows: 3, width: '100%' }} loading={loading} active avatar />
          <Skeleton paragraph={{ rows: 3, width: '100%' }} loading={loading} active avatar />
          <Skeleton paragraph={{ rows: 3, width: '100%' }} loading={loading} active avatar />
          <Skeleton paragraph={{ rows: 3, width: '100%' }} loading={loading} active avatar />
        </>
      ) : (
        <Spin indicator={null} spinning={loadingMore && hasMore && !isPaidFeature}>
          {data.length > 0 ? (
            <ul className="list-unstyled" style={{ paddingTop: 8 }}>
              {data.slice(0, isPaidFeature ? 4 : data.length).map((item) => {
                return (
                  <TimeLineItem
                    key={item.id}
                    isPaidFeature={isPaidFeature}
                    onHideParent={onHideParent}
                    item={item}
                    propertyTimeZone={options.property_timezone}
                  />
                )
              })}
            </ul>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Spin>
      )}

      {/* <div style={{ position: 'relative' }}>
        <Spinner spinning={loadingMore && hasMore && !isPaidFeature} style={{ left: '50%' }} />
      </div> */}
    </InfiniteScroll>
  )
}

export default Timeline
