import axios from 'axios'

export const getPriceRules = (params) => {
  return axios.get('/finance/priceRules', { params })
}

export const addPriceRule = (data) => {
  return axios.post('/finance/priceRules', data)
}

export const editPriceRule = (data) => {
  return axios.put(`/finance/priceRules/${data.id}`, data)
}

export const getPriceRule = (id) => {
  return axios.get(`/finance/priceRules/${id}`)
}

export const deletePriceRule = (id) => {
  return axios.delete(`/finance/priceRules/${id}`)
}

export const generateImportPriceRule = (params) => {
  return axios.get('/finance/priceRules/generateImport', { responseType: 'arraybuffer', params })
}

export const uploadImportPriceRule = (data) => {
  return axios.postForm('/finance/priceRules/uploadImport', data)
}

export const executeImportPriceRule = (data) => {
  return axios.post('/finance/priceRules/executeImport', data)
}
