import React, { memo } from 'react'
import { Dropdown, Menu, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { changeLanguage } from 'redux/settings/actions'
import { compareWithJSON } from 'utils/helper'
import { injectIntl } from 'react-intl'
import styles from './style.module.scss'

@connect(
  ({ settings, refs: { lang } }) => ({ settings, lang }),
  (dispatch) => {
    return {
      onChangeLanguage: (payload) => dispatch(changeLanguage(payload)),
    }
  },
)
class LanguageSelector extends React.PureComponent {
  state = {
    visibleDropdown: false,
  }

  changeLang = ({ key }) => {
    const {
      onChangeLanguage,
      settings: { locale },
    } = this.props
    if (locale !== key) {
      onChangeLanguage({
        setting: 'locale',
        value: key,
      })
    }
  }

  render() {
    const {
      settings: { locale },
      intl,
    } = this.props
    const language = locale.substr(0, 2)
    const items = this.props.lang.map((item) => {
      return {
        key: item.value,
        label: (
          <>
            <span role="img" aria-label={item.name} className="mr-2 text-uppercase">
              {item.value.substr(0, 2)}
            </span>
            {item.name}
          </>
        ),
      }
    })

    const langMenu = (
      <Menu
        className={styles.menu}
        selectedKeys={[locale]}
        onClick={this.changeLang}
        items={items}
      />
    )
    return (
      <Tooltip
        open={this.state.visibleDropdown ? false : undefined}
        title={intl.formatMessage({ id: 'topBar.changeLanguage' })}
      >
        <div>
          <Dropdown
            open={this.state.visibleDropdown}
            onOpenChange={(visible) => this.setState({ visibleDropdown: visible })}
            dropdownRender={() => langMenu}
            trigger={['click']}
          >
            <div className={styles.dropdown}>
              <strong className="text-uppercase">{language}</strong>
            </div>
          </Dropdown>
        </div>
      </Tooltip>
    )
  }
}

export default memo(injectIntl(LanguageSelector), compareWithJSON)
