import axios from 'axios'
import { stringify } from 'query-string'
import { getOnce } from 'utils/helper'

export const getFinanceTags = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/tags?${query}`)
}

export const addFinanceTag = (data) => {
  return axios.post('/finance/tags', data)
}

export const editFinanceTag = (data) => {
  return axios.put(`/finance/tags/${data.id}`, data)
}

export const getSuggestionFinanceTag = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/tags/suggestionPerPage?${query}`)
}

export const getFinanceTag = (id, params) => {
  const query = stringify(params)
  return axios.get(`/finance/tags/${id}?${query}`)
}

export const deleteFinanceTag = (id) => {
  return axios.delete(`/finance/tags/${id}`)
}

export const getFinanceTagRoles = (id) => {
  return axios.get(`/finance/tags/${id}/roles`)
}

export const addFinanceTagRoles = (id, data) => {
  return axios.post(`/finance/tags/${id}/roles`, data)
}

export const getFinanceTagDetail = (params) => {
  const query = stringify(params)
  return axios.get(`/finance/tags?${query}`)
}

export const uploadImportFinanceTag = (data) => {
  return axios.postForm('/finance/tags/uploadImport', data)
}

export const executeImportFinanceTag = (data) => {
  return axios.post('/finance/tags/executeImport', data)
}
