import axios from 'axios'
import { stringify } from 'query-string'

export const getProductFavorites = () => {
  return axios.get('/pos/products/favorites')
}

export const createProductFavorites = (payload) => {
  return axios.post('/pos/products/favorites', payload)
}

export const deleteProductFavorites = ({ id }) => {
  return axios.delete(`/pos/products/favorites/${id}`)
}

export const moveProductFavorite = ({ id, payload }) => {
  return axios.patch(`/pos/products/favorites/${id}/move`, payload)
}

export const getOutlets = ({ payload }) => {
  const query = stringify(payload)
  return axios.get(`/pos/outlets?${query}`)
}

export const createOutlet = ({ payload }) => {
  return axios.post('/pos/outlets', payload)
}

export const editOutlet = ({ id, payload }) => {
  return axios.put(`/pos/outlets/${id}`, payload)
}

export const deleteOutlet = ({ id }) => {
  return axios.delete(`/pos/outlets/${id}`)
}

export const getPosUsers = () => {
  return axios.get('/pos/users')
}

export const getPosRoles = () => {
  return axios.get('/pos/roles')
}

export const editPosRole = ({ id, payload }) => {
  return axios.patch(`/pos/roles/${id}`, payload)
}
