import axios from 'axios'
import { cleanBlankValue, getOnce, openNewTabBlobFile } from 'utils/helper'

export const getFinanceInvoices = (params) => {
  return getOnce('/finance/invoices', { params })
}

export const getTotalFinanceInvoices = (params) => {
  return axios.get('/finance/invoices/totals', { params })
}

export const addFinanceInvoice = (data) => {
  return axios.post('/finance/invoices', data)
}

export const editFinanceInvoice = (data) => {
  return axios.put(`/finance/invoices/${data.id}`, data)
}

export const deleteOnAddFinanceInvoiceAttachment = (url) => {
  return axios.delete('/finance/invoices/attachments', { data: { url } })
}

export const deleteOnEditFinanceInvoiceAttachment = (id, url) => {
  return axios.delete(`/finance/invoices/${id}/attachments`, { data: { url } })
}

export const getFinanceInvoice = (id, params) => {
  return axios.get(`/finance/invoices/${id}`, { params })
}

export const exportFinanceInvoiceReceipt = (id, printURL) => {
  return axios.get(`/finance/invoices/${id}/downloadReceipt/${printURL}`)
}

export const getFinanceInvoiceLog = (id, params) => {
  return axios.get(`/finance/invoices/${id}/logs`, { params })
}

export const changeFinanceInvoiceStatus = (id, data) => {
  return axios.patch(`/finance/invoices/${id}/status`, data)
}

export const deleteFinanceInvoice = (id) => {
  return axios.delete(`/finance/invoices/${id}`)
}

export const getFinanceInvoiceOverview = (params) => {
  return axios.get(`/finance/invoices/overview`, { params })
}

export const uploadImportFinanceInvoice = (data) => {
  return axios.postForm('/finance/invoices/uploadImport', data)
}

export const executeImportFinanceInvoice = (data) => {
  return axios.post('/finance/invoices/executeImport', data)
}

export const uploadImportFinanceInvoiceRecurring = (data) => {
  return axios.postForm('/finance/invoices/recurring/uploadImport', data)
}

export const executeImportFinanceInvoiceRecurring = (data) => {
  return axios.post('/finance/invoices/recurring/executeImport', data)
}

export const uploadImportStatusFinanceInvoice = (data) => {
  return axios.postForm('/finance/invoices/payment/uploadImport', data)
}

export const executeImportStatusFinanceInvoice = (data) => {
  return axios.post('/finance/invoices/payment/executeImport', data)
}

export const uploadImportFinanceInvoiceReturn = (data) => {
  return axios.postForm('/finance/invoices/returns/uploadImport', data)
}

export const executeImportFinanceInvoiceReturn = (data) => {
  return axios.post('/finance/invoices/returns/executeImport', data)
}

export const createPdfFinanceInvoice = (id) => {
  return axios
    .get(`/finance/invoices/${id}/print`, { responseType: 'blob' })
    .then((response) => {
      openNewTabBlobFile(response)
      return Promise.resolve(true)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const changeFinanceInvoiceTaxNumber = (id, data) => {
  return axios.patch(`/finance/invoices/${id}/tax_number`, data)
}

export const getFinanceInvoicePublicUrl = (id) => {
  return axios.get(`/finance/invoices/${id}/publicUrl`)
}

export const exportFinanceInvoice = (params) => {
  const URL = params.status_id === 'recurring' ? 'invoices/recurring/export' : 'invoices/export'
  if (params.status_id === 'recurring') {
    delete params.status_id
  }
  const query = cleanBlankValue(params)
  return axios.get(`/finance/${URL}`, { responseType: 'arraybuffer', params: query })
}

export const exportFinanceInvoiceEtax = (params) => {
  const URL = params.status_id === 'recurring' ? 'invoices/recurring/export' : 'invoices/export'
  if (params.status_id === 'recurring') {
    delete params.status_id
  }
  const query = cleanBlankValue(params)

  return axios.get(`/finance/${URL}/eTax`, {
    responseType: 'arraybuffer',
    params: query,
  })
}

export const getFinanceInvoiceWaTemplate = (id) => {
  return axios.get(`/finance/invoices/${id}/whatsapp`)
}

export const getFinanceInvoiceWaRecapTemplate = (id) => {
  return axios.get(`/finance/invoices/${id}/whatsappRecap`)
}

export const getFinanceInvoiceEmailTemplate = (id) => {
  return axios.get(`/finance/invoices/${id}/email`)
}

export const sendFinanceInvoiceEmail = (id, data) => {
  return axios.post(`/finance/invoices/${id}/email`, data)
}

export const getInvoiceEmailThankYouTemplate = (id) => {
  return axios.get(`/finance/invoices/${id}/emailThankYou`)
}

export const sendInvoiceEmailThankYou = (id, data) => {
  return axios.post(`/finance/invoices/${id}/emailThankYou`, data)
}

export const getFinanceInvoiceEmailRecapTemplate = (id) => {
  return axios.get(`/finance/invoices/${id}/emailRecap`)
}

export const sendFinanceInvoiceEmailRecap = (id, data) => {
  return axios.post(`/finance/invoices/${id}/emailRecap`, data)
}

export const addRecurringInvoice = (data) => {
  return axios.post('/finance/invoices/recurring', data)
}

export const editRecurringInvoice = (data) => {
  return axios.put(`/finance/invoices/recurring/${data.id}`, data)
}

export const getRecurringInvoice = (id) => {
  return axios.get(`/finance/invoices/recurring/${id}`)
}

export const deleteRecurringInvoice = (id) => {
  return axios.delete(`/finance/invoices/recurring/${id}`)
}

export const getRecurringInvoices = (params) => {
  return getOnce('/finance/invoices/recurring', { params })
}

export const getFinanceInvoiceSmsTemplate = (id) => {
  return axios.get(`/finance/invoices/${id}/sms`)
}

export const sendFinanceInvoiceSms = (id, data) => {
  return axios.post(`/finance/invoices/${id}/sms`, data)
}

export const getFinanceInvoiceSmsRecapTemplate = (id) => {
  return axios.get(`/finance/invoices/${id}/smsRecap`)
}

export const sendFinanceInvoiceSmsRecap = (id, data) => {
  return axios.post(`/finance/invoices/${id}/smsRecap`, data)
}

export const inputMassDeleteFinanceInvoice = (data) => {
  return axios.post('/finance/invoices/inputMassDelete', data)
}

export const executeMassDeleteFinanceInvoice = (data) => {
  return axios.delete('/finance/invoices/executeMassDelete', { data })
}

export const inputMassSendEmailFinanceInvoice = (data) => {
  return axios.post('/finance/invoices/inputMassEmail', data)
}

export const executeMassSendEmailFinanceInvoice = (data) => {
  return axios.post('/finance/invoices/executeMassEmail', data)
}

export const getMassDownloadFinanceInvoice = (params) => {
  return axios.get('/finance/invoices/massDownload', { params })
}

export const getMassDownloadLabelFinanceInvoice = (params) => {
  return axios.get('/finance/invoices/massDownloadLabel', { params })
}

export const getMassDownloadDeliveryFinanceInvoice = (params) => {
  return axios.get('/finance/invoices/massDownloadDelivery', { params })
}

export const getMassDownloadPaymentFinanceInvoice = (params) => {
  return axios.get('/finance/invoices/massDownloadPayment', { params })
}

export const inputMassCreateFinanceInvoice = (data) => {
  return axios.post('/finance/invoices/inputMassInvoice', data)
}

export const getMinTransDateFinanceInvoice = (data) => {
  return axios.post('/finance/invoices/mass/minDate', data)
}

export const executeMassCreateFinanceInvoice = (data) => {
  return axios.post('/finance/invoices/executeMassInvoice', data)
}

export const voidFinanceInvoice = (id, body) => {
  return axios.post(`/finance/invoices/${id}/void`, body)
}

export const unvoidFinanceInvoice = (id) => {
  return axios.post(`/finance/invoices/${id}/unvoid`)
}

export const approveFinanceInvoice = ({ id }) => {
  return axios.post(`/finance/invoices/${id}/approve`)
}

export const rejectFinanceInvoice = ({ id, body }) => {
  return axios.post(`/finance/invoices/${id}/reject`, body)
}

export const revertApprovalFinanceInvoice = ({ id }) => {
  return axios.post(`/finance/invoices/${id}/revert`)
}

export const stampingInvoice = (id, data) => {
  return axios.post(`/finance/invoices/${id}/stamp`, data)
}

export const getTotalInvoices = (params) => {
  return axios.get('/finance/invoices/totals', { params })
}
