import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import {
  getFinancePurchaseDelivery,
  getFinancePurchaseDeliverys,
  getMinTransDateFinancePurchaseDelivery,
  getTotalFinancePurchaseDeliveries,
  inputMassCreateFinancePurchaseDelivery,
} from 'utils/apis'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const usePurchaseDeliveries = ({ payload = {}, enabled = false }) => {
  const options = useSelector(optionsSelector)
  const dispatch = useDispatch()

  // prevent user that already setting per_page > 100
  if (payload.per_page > 100) {
    payload.per_page = 100
  }

  if (payload.per_page && payload.per_page !== options.purchases_per_page_default) {
    dispatch(
      editOption({
        purchases_per_page_default: payload.per_page,
      }),
    )
  }

  return useQuery(
    ['purchaseDelivery', payload],
    async () => {
      const {
        data: { data },
      } = await getFinancePurchaseDeliverys(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useTotalPurchaseDeliveries = (payload = {}, options = {}) => {
  return useQuery(
    ['total-purchase-deliveries', payload],
    async () => {
      const {
        data: { data },
      } = await getTotalFinancePurchaseDeliveries(payload)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export function usePurchaseDelivery({ id, payload = {}, enabled = false }) {
  return useQuery(
    ['purchaseDelivery', id, payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinancePurchaseDelivery(id, payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export const useGetMinTransDateFinancePurchaseDeliveryMutation = () => {
  return useMutation((payload) =>
    getMinTransDateFinancePurchaseDelivery({ ...payload, trans_type: 'purchase_delivery' }),
  )
}

export const useInputMassCreateFinancePurchaseDeliveryMutation = () => {
  return useMutation(inputMassCreateFinancePurchaseDelivery)
}
