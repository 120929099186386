import React, { memo, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
  SolutionOutlined,
  PlayCircleOutlined,
  BookOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { compareWithJSON } from 'utils/helper'
import DropdownClickButton from '../DropdownClickButton'

const HelpDropdown = ({ onClickVideo, onClickArticle, onClickTour, isMobileView, ...props }) => {
  const { formatMessage } = useIntl()

  const clickVideoHandler = useCallback(() => {
    if (typeof onClickVideo === 'function') {
      onClickVideo()
    }
  }, [onClickVideo])
  const clickTourHandler = useCallback(() => {
    if (typeof onClickTour === 'function') {
      onClickTour()
    }
  }, [onClickTour])
  const clickArticleHandler = useCallback(() => {
    if (typeof onClickArticle === 'function') {
      onClickArticle()
    }
  }, [onClickArticle])

  const items = useMemo(() => {
    const __items = []
    if (typeof onClickTour === 'function') {
      __items.push({
        key: '1',
        icon: <SolutionOutlined />,
        onClick: clickTourHandler,
        label: formatMessage({ id: 'button.tour' }),
      })
    }
    if (typeof onClickVideo === 'function') {
      __items.push({
        key: '2',
        icon: <PlayCircleOutlined />,
        onClick: clickVideoHandler,
        label: formatMessage({ id: 'button.video' }),
      })
    }
    if (typeof onClickArticle === 'function') {
      __items.push({
        key: '3',
        icon: <BookOutlined />,
        onClick: clickArticleHandler,
        label: formatMessage({ id: 'button.article' }),
      })
    }
    return __items
  }, [
    clickArticleHandler,
    clickTourHandler,
    clickVideoHandler,
    formatMessage,
    onClickArticle,
    onClickTour,
    onClickVideo,
  ])

  return (
    // <Dropdown.Button
    //   icon={isMobileView ? <QuestionCircleOutlined /> : <DownOutlined />}
    //   menu={{ items }}
    //   trigger={['click']}
    //   {...props}
    // >
    //   {!isMobileView && (
    //     <>
    //       <QuestionCircleOutlined /> {formatMessage({ id: 'button.guide' })}
    //     </>
    //   )}
    // </Dropdown.Button>
    <DropdownClickButton
      menu={{ items }}
      buttonLeftProps={{
        children: formatMessage({ id: 'button.guide' }),
        icon: <QuestionCircleOutlined />,
      }}
      id="dropdown-click-help-preview"
      placement="bottomRight"
      {...props}
    >
      <QuestionCircleOutlined /> {formatMessage({ id: 'button.guide' })}
    </DropdownClickButton>
  )
}

export default memo(HelpDropdown, compareWithJSON)
