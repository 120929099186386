import axios from 'axios'

import { cleanBlankValue } from 'utils/helper'

export const getFinanceLogs = (params) => {
  return axios.get('/finance/logs', { params })
}

export const getLogByTransType = (params) => {
  return axios.get('/finance/logs/transType', { params })
}

export const exportFinanceLogs = (params) => {
  params = cleanBlankValue(params)

  return axios.get('/finance/logs', { responseType: 'arraybuffer', params })
}
